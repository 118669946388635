import React from 'react';
import './FeaturedProperties.css';
import FeaturedPropertyCard from 'components/FeaturedPropertyCard';

const FeaturedProperties = ({ isHome = false }) => {
  const properties = [
    {
      id: 117,
      name: 'ASO GARDEN ESTATE',
      address: 'ASO Garden Estate, Plot 57, Karsana East District, Abuja',
      price: '18,000,000.00',
      units: '46 Units',
      views: '79',
      favorites: '122',
      no_bedroom: '4',
      no_bathroom: '4',
      garages: '5',
      image: '/assets/aso-garden.jpg',
    },
    {
      id: 119,
      name: 'SHOLES CITY ESTATE',
      address: 'LIFE CAMP - 2 Bedroom Flat',
      price: '16,500,000.00',
      units: '4 Units',
      views: '450',
      favorites: '25',
      no_bedroom: '3',
      no_bathroom: '3',
      garages: '3',

      image: 'https://admin.100bricks.ng/storage/property/119/1623414593.jpg',
    },
    {
      id: 96,
      name: 'GRAND COURT ESTATE',
      address: 'Close to Orchard Hotel, After Chevron Roundabout, Lekki, Lagos',
      price: '60,000,000.00',
      units: '30 Units',
      views: '350',
      favorites: '72',
      no_bedroom: '4',
      no_bathroom: '4',
      garages: '2',
      image: '/assets/New Listings.jpg',
    },
  ];

  return (
    <section className="container">
      <div className="featured-properties">
        <h2 className="title">FEATURED PROPERTIES</h2>
        <p className="description">
          Explore our new listings featuring a variety of real estate and property
          types
        </p>
        <div className="property-grid">
          {properties.map((property) => (
            <FeaturedPropertyCard
              key={property.id}
              property={property}
              isHome={isHome}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturedProperties;
