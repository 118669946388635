import React from 'react';
import './FeaturedPropertyCard.css';
import { FaBath, FaBed, FaEye, FaMapMarked } from 'react-icons/fa';
import { HiHeart } from 'react-icons/hi';
import { moneyFormat } from 'utils/moneyFormat';
import { useHistory } from 'react-router-dom';
import { truncateText } from 'utils/dateUtils';

const FeaturedPropertyCard = ({ property, isHome = false }) => {
  const history = useHistory();

  const handleClickAction = () => {
    history.push({
      pathname: `/property/${property?.id}`,
      state: {
        propertyID: property?.id,
      },
    });
  };

  return (
    <div className="featured-property-card" onClick={handleClickAction}>
      <img
        src={property?.image}
        alt={property?.name}
        className="property-image"
        onError={(e) => (e.target.src = '/assets/property-img-default.gif')}
      />
      <div className="property-info">
        <h3 className={`title ${isHome ? '' : 'titlev2'}`}>
          {truncateText(property?.name, isHome ? 200 : 19)}{' '}
          <span>{property?.units}</span>
        </h3>
        <p className={`location ${isHome ? '' : 'locationv2'}`}>
          <FaMapMarked /> {truncateText(property?.address, isHome ? 40 : 25)}
        </p>
        <div className="price-container">
          <p className={`price ${isHome ? '' : 'price2'}`}>
            NGN {moneyFormat(property?.price)}
          </p>
          <div className="meta">
            <span className="d-flex align-items-center gap-1">
              <FaEye size={25} /> {property?.view_count} Views
            </span>
            <span className="d-flex align-items-center gap-1">
              <HiHeart size={25} color="#CC5500" stroke="black" strokeWidth={1} />
              {property?.favorites}
            </span>
          </div>
        </div>
        <div className="details">
          <span className="d-flex align-items-center gap-2">
            <FaBed size={25} /> {property?.no_bedroom}
          </span>
          |
          <span className="d-flex align-items-center gap-3">
            <FaBath size={25} /> {property?.no_bathroom}
          </span>
          |
          <span className="d-flex align-items-center gap-3">
            <img src="/assets/icons/toilet.jpg" height={25} width={25} />{' '}
            {property.garages}
          </span>
        </div>
      </div>
    </div>
  );
};

export default FeaturedPropertyCard;
